import { Component, EventEmitter, Input, OnInit, Output, Inject, forwardRef } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

@Component({
    selector: "dm-datepicker",
    templateUrl: "./datepicker.html",
    styleUrls: ["./datepicker.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DmDatepickerComponent),
            multi: true
        }
    ]
})
export class DmDatepickerComponent implements OnInit, ControlValueAccessor {

    @Input() // Minimum date the datepicker should allow
    public set minDate(date: Date) {
        this.ngbMinDate = this.convertDateToNgbDateStruct(date);
    }
    public get minDate(): Date {
        return this.convertNgbDateStructToDate(this.ngbMinDate);
    }
    @Input() // Maximum date the datepicker should allow
    public set maxDate(date: Date) {
        this.ngbMaxDate = this.convertDateToNgbDateStruct(date);
    }
    public get maxDate(): Date {
        return this.convertNgbDateStructToDate(this.ngbMaxDate);
    }
    @Input() // Maximum date the datepicker should allow
    public set startDate(date: Date) {
        this.ngbStartDate = this.convertDateToNgbDateStruct(date);
    }
    public get startDate(): Date {
        return this.convertNgbDateStructToDate(this.ngbStartDate);
    }
    @Input() public disabled: boolean; // Enable/disable the datepicker
    @Input() public modelDate: Date; // Date the datepicker should be bound to
    @Input() public customClasses: string; // Extra classes that are applied on top of the default classes for styling
    @Input() public ariaLabelCalendar: string; // aria-label for the datepicker text input, used for the screenreader
    @Input() public ariaLabelButton: string; // aria-label for the calendar button, used for the screenreader
    @Input() public placeholder: string = ""; // The placeholder attribute specifies a short hint that describes the expected value of a input field / textarea.
    @Input() public isMandatory: boolean = false; // to add required property to the input field if it is mandatory
    @Input() public previosFousingElementId: string; // id for the previous element on which focus should come on shift tab, when the date picker is in closed state.
    @Input() public showErrorBorder: boolean = false;
    @Input() public isDatePickerV2: boolean = false;
    @Output() public onDateChange: EventEmitter<Date> = new EventEmitter();

    public selectedDate: NgbDateStruct;
    public ngbMinDate: NgbDateStruct;
    public ngbMaxDate: NgbDateStruct;
    public ngbStartDate: NgbDateStruct;
    public focusingCalenderBtnId: string;
    public datePickerId: string;

    public onChange: (...args: any[]) => void;
    public onTouched: (...args: any[]) => void;
    @Input() public get value(): any {
        return this.modelDate;
    }
    /* The getter and setter should actually be Date and NgbDateStruct, respectively,
    but linting errors are thrown when they are not the same type, so we've set them as any. */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public set value(val: any) {
        if (val !== null && val !== undefined) {
            this.modelDate = this.convertNgbDateStructToDate(val);
            this.selectedDate = val;

            if (this.onChange) {
                this.onChange(this.modelDate);
            }

            if (this.onTouched) {
                this.onTouched(this.modelDate);
            }
        }
    }


    public constructor(
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
    }

    public ngOnInit(): void {
        /* Dates for the datepicker must be in this specific format, so
        convert the given date to the special datepicker format */
        this.selectedDate = this.modelDate ? this.convertDateToNgbDateStruct(this.modelDate) : undefined;
        this.ngbStartDate = this.selectedDate;
        this.focusingCalenderBtnId = "calenderBtn_" + this.ariaLabelButton;
        this.datePickerId = "datePicker_" + this.ariaLabelButton;
    }

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }
    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    // This is a basic setter that the forms API is going to use
    public writeValue(value: Date): void {
        if (value !== null && value !== undefined) {
            this.modelDate = value;
            this.selectedDate = this.convertDateToNgbDateStruct(this.modelDate);
        }
    }


    /**
     * Selects the date and converts it to a Date format and passes it back to the input value
     */
    public onDateSelect(): void {
        this.modelDate = this.convertNgbDateStructToDate(this.selectedDate);
        this.onDateChange.emit(this.modelDate);
    }

    /**
     * Sets the selected date to today. Can be activated by clicking enter.
     */
    public setDateToToday(event?: KeyboardEvent): void {
        if (!event || (event && event.keyCode === 13)) {
            this.selectedDate = this.convertDateToNgbDateStruct(new Date());
            this.onDateSelect();
        }
    }

    /**
     * Clears the selected date. Can be activated by clicking enter.
     */
    public clearDate(event?: KeyboardEvent): void {
        if (!event || (event && event.keyCode === 13)) {
            this.selectedDate = undefined;
            this.onDateSelect();
        }
    }

    /**
     * Output date as string in the specified format
     */
    public formatDateToString(date: Date): string {
        return moment(date).format("DD-MMM-YYYY");
    }

    /**
     * Moves the focus on the calender opened.
     */
    public moveFocusToCalender(event: KeyboardEvent): void {
        if (event.keyCode === 13 || event.keyCode === undefined) {
            this.sharedFunctionsService.moveFocus(event, this.datePickerId, this.datePickerId);
            if (document.getElementById("selctedDate")) {
                document.getElementById("selctedDate").remove();
            }
            this.sharedFunctionsService.removeAttribute("dm-modal-v2", "class", "role", 0);
            this.sharedFunctionsService.removeAttribute("modal-dialog", "class", "role", 0);
        }
        this.addAtrributesToNgbDatePickerButtons();
        // Gets the date elements and move focus to the date as per the selection to highlight it.
        const dateElements = Array.from(document.getElementById(this.datePickerId).getElementsByClassName("btn-light"));
        for (const dateElement of dateElements) {
            const parentEleAriaLabel = dateElement.parentElement ? dateElement.parentElement.getAttribute("aria-label").replace(/, /g, "") : "";
            if (dateElement.classList.toString().indexOf("text-muted") === -1) {
                if (this.selectedDate && this.selectedDate.day && dateElement.textContent === this.selectedDate.day.toString()) {
                    this.sharedFunctionsService.focus(this.datePickerId + "-ngb-dp-day-" + parentEleAriaLabel + "-" + dateElement.textContent, true);
                    break;
                } else if (!this.selectedDate) {
                    this.sharedFunctionsService.focus(this.datePickerId + "-ngb-dp-day-" + parentEleAriaLabel + "-" + dateElement.textContent, true);
                    break;
                }
            }
        }
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event?: KeyboardEvent): void {
        if (event && (event.keyCode === 9 && !event.shiftKey)) {
            this.sharedFunctionsService.moveFocus(event, this.focusingCalenderBtnId, this.focusingCalenderBtnId);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     */
    public moveFocusPrev(event: KeyboardEvent, isOpen: boolean): void {
        const focusingElement: string = isOpen ? ("closeBtn_" + this.ariaLabelButton) : this.previosFousingElementId;
        if (event && (event.keyCode === 9 && event.shiftKey)) {
            this.sharedFunctionsService.moveFocus(event, focusingElement, focusingElement);
        }
    }

    /**
    * Triggers on click of previous and next navigation buttons in the datepicker.
    */
    public onNavigation(event: { current: { year: number; month: number }; next: { year: number; month: number } }): void {
        if (document.getElementById("month_value")) {
            document.getElementById("month_value").innerHTML = moment.months(event.next.month - 1) + " " + event.next.year.toString();
        }
        if (document.getElementById("caption_value")) {
            document.getElementById("caption_value").innerHTML = "Month " + moment.months(event.next.month - 1) + " Year " + event.next.year.toString();
        }
    }
    
    /**
     * Triggers whenever the calender is toggled.
     * Sets the focus on the parent calender button whenever the calender closes.
     */
    public toggledCalender(isCalenderOpen: boolean): void {
        // sets the caption for the month table
        if (document.getElementById("caption_value")) {
            document.getElementById("caption_value").innerHTML = "Month " + moment.months(this.selectedDate.month - 1) + " Year " + this.selectedDate.year.toString();
        }
        if (!isCalenderOpen) {
            this.sharedFunctionsService.focus(this.focusingCalenderBtnId, false);
        }
    }

    /**
     * Converts the given date object into an ngbDateStruct, including
     * offsetting the month by 1 due to 0 vs 1 indexing in the object.
     * @param date
     */
    private convertDateToNgbDateStruct(date: Date): NgbDateStruct {
        const momentDate = moment(date);
        if (date) {
            return {
                year: momentDate.year(),
                month: momentDate.month() + 1,
                day: momentDate.date(),
            };
        }
        return undefined;
    }

    /**
     * Converts the given ngbDateStruct (value from the datepicker) to a Date object,
     * including offsetting the month by 1 due to 0 vs 1 indexing in the object.
     * @param ngbDate
     */
    private convertNgbDateStructToDate(ngbDate: NgbDateStruct): Date {
        if (ngbDate) {
            const convertedDateStruct: NgbDateStruct = { ...ngbDate, month: ngbDate.month - 1 };
            /*
             * Due to moment automatically assigning timezones, we have to push the date through a couple different conversions to get the value we want.
             * All dates should be viewed in the user's local time, but there should be "no time" assigned to the dates, so that a user in India will
             * always see the same date as a user in America.
             *
             * If you are doing date manipulation and change the following code, please check carefully across multiple timezones for expected behavior.
             * For maximum control, if pushing any dates to an API, recommend that you convert the date to a string before sending it.
             *
             * What this code does:
             * 1. Takes the date struct and reads it as UTC
             * 2. Converts that to an ISO string in order to pull off any timezone data
             * 3. Rebuilds the moment object with specific string format without any timezone data, since moment will otherwise assume local time/timezone
             * 4. Converts that new moment into a date, which is then returned. Date will be set as midnight UTC of the selected date.
             */
            const date = moment(moment.utc(convertedDateStruct).toISOString(), "YYYY-MM-DD HH:mm").toDate();
            return date;
        }
        return undefined;
    }

    /**
     * Sets id's and aria labels for ngb datepicker buttons
     */
    private addAtrributesToNgbDatePickerButtons(): void {
        const previousMonthBtnId: string = "previousMonthBtn_" + this.ariaLabelButton;
        const nextMonthBtnId: string = "nextMonthBtn_" + this.ariaLabelButton;
        const monthDropDownId: string = "month_" + this.ariaLabelButton;
        const yearDropDownId: string = "year_" + this.ariaLabelButton;
        const previousMonthBtnElement: Element = document.getElementById(this.datePickerId).getElementsByClassName("ngb-dp-arrow-btn")[0];
        const nextMonthBtnElement: Element = document.getElementById(this.datePickerId).getElementsByClassName("ngb-dp-arrow-btn")[1];
        const monthDropDownElement: Element = document.getElementById(this.datePickerId).getElementsByClassName("ngb-dp-navigation-select")[0].getElementsByClassName("custom-select")[0];
        const yearDropDownElement: Element = document.getElementById(this.datePickerId).getElementsByClassName("ngb-dp-navigation-select")[0].getElementsByClassName("custom-select")[1];
        previousMonthBtnElement.setAttribute("id", previousMonthBtnId);
        nextMonthBtnElement.setAttribute("id", nextMonthBtnId);
        previousMonthBtnElement.setAttribute("aria-label", "go back to previous month to set " + this.ariaLabelButton);
        nextMonthBtnElement.setAttribute("aria-label", "go to next month to set " + this.ariaLabelButton);
        monthDropDownElement.setAttribute("id", monthDropDownId);
        yearDropDownElement.setAttribute("id", yearDropDownId);
        monthDropDownElement.setAttribute("aria-label", "select month to set " + this.ariaLabelButton);
        yearDropDownElement.setAttribute("aria-label", "select year to set " + this.ariaLabelButton);
        const span = document.createElement("span");
        span.innerHTML = moment(this.modelDate).format("MMMM YYYY") + " calendar " + moment(this.modelDate).format("DD dddd");
        span.className = "sr-only";
        span.setAttribute("id", "selctedDate");
        // Get a reference to the element, before we want to insert the element
        const insertBeforeElement: Element = document.getElementById(this.datePickerId).getElementsByClassName("ngb-dp-header")[0];
        document.getElementById(this.datePickerId).insertBefore(span, insertBeforeElement);
        // Get date element and parent element and add id's to them
        const dateElements = Array.from(document.getElementById(this.datePickerId).getElementsByClassName("btn-light"));
        for (const dateElement of dateElements) {
            const parentEleAriaLabel = dateElement.parentElement ? dateElement.parentElement.getAttribute("aria-label").replace(/, /g, "") : "";
            const dayId = (dateElement.classList.toString().indexOf("text-muted") !== -1) ? this.datePickerId + "-text-muted-btn-light-" + parentEleAriaLabel + "-" + dateElement.textContent : this.datePickerId + "-btn-light-" + parentEleAriaLabel + "-" + dateElement.textContent;
            const dayParentId = (dateElement.classList.toString().indexOf("text-muted") !== -1) ? this.datePickerId + "-text-muted-ngb-dp-day-" + parentEleAriaLabel + "-" + dateElement.textContent : this.datePickerId + "-ngb-dp-day-" + parentEleAriaLabel + "-" + dateElement.textContent;
            dateElement.setAttribute("id", dayId);
            dateElement.parentElement.setAttribute("id", dayParentId);
        }
        if (document.getElementsByTagName("ngb-datepicker-month-view")) {
            const monthViewElements = Array.from(document.getElementsByTagName("ngb-datepicker-month-view"));
            if (monthViewElements && monthViewElements.length) {
                for (const monthViewElement of monthViewElements) {
                    monthViewElement.setAttribute("aria-labelledby", "caption_value");
                }
            }
        }
        // Replace week short name with full name to make the screen reader read full name of the week
        if (document.getElementsByClassName("ngb-dp-weekday")) {
            const weekElements = Array.from(document.getElementsByClassName("ngb-dp-weekday"));
            if (weekElements && weekElements.length) {
                for (const weekElement of weekElements) {
                    if (weekElements.indexOf(weekElement) === 0 || weekElements.indexOf(weekElement) - 7 === 0) {
                        weekElement.innerHTML = "Monday";
                        weekElement.setAttribute("id", "ngb-dp-weekday-0");
                    } else if (weekElements.indexOf(weekElement) === 1 || weekElements.indexOf(weekElement) - 7 === 1) {
                        weekElement.innerHTML = "Tuesday";
                        weekElement.setAttribute("id", "ngb-dp-weekday-1");
                    } else if (weekElements.indexOf(weekElement) === 2 || weekElements.indexOf(weekElement) - 7 === 2) {
                        weekElement.innerHTML = "Wednesday";
                        weekElement.setAttribute("id", "ngb-dp-weekday-2");
                    } else if (weekElements.indexOf(weekElement) === 3 || weekElements.indexOf(weekElement) - 7 === 3) {
                        weekElement.innerHTML = "Thursday";
                        weekElement.setAttribute("id", "ngb-dp-weekday-3");
                    } else if (weekElements.indexOf(weekElement) === 4 || weekElements.indexOf(weekElement) - 7 === 4) {
                        weekElement.innerHTML = "Friday";
                        weekElement.setAttribute("id", "ngb-dp-weekday-4");
                    } else if (weekElements.indexOf(weekElement) === 5 || weekElements.indexOf(weekElement) - 7 === 5) {
                        weekElement.innerHTML = "Saturday";
                        weekElement.setAttribute("id", "ngb-dp-weekday-5");
                    } else if (weekElements.indexOf(weekElement) === 6 || weekElements.indexOf(weekElement) - 7 === 6) {
                        weekElement.innerHTML = "Sunday";
                        weekElement.setAttribute("id", "ngb-dp-weekday-6");
                    }
                }
            }
        }
    }
}
