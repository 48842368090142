<section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-0" *ngIf="!deviceFactory.isMobile()">
    <section class="tile--no-pad col-md-6 col-sm-8 dm-p-l-imp-0">
        <section class="section__wrapper input__label dm-p-l-imp-0">
            <label class="input__label--text" for="dm-gr-projects-ddl">
                <span> Purchase Order</span>
            </label>
            <label class="input__select--icon">
                <select id="dm-gr-po-details" class="input__select input__select--big" [(ngModel)]="selectedPo"
                    (ngModelChange)="onSelectedPoChange()" aria-label="Select Purchase Order">
                    <option value="">All Purchase Orders</option>
                    <option *ngFor="let po of poDropDownList" [value]="po.poNumber">
                        {{po.poNumber}}{{po.poTitle ? " - " + po.poTitle: "" }}
                    </option>
                </select>
                <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
            </label>
        </section>
    </section>
    <section class="col-md-6 col-sm-4 tile--no-pad">
        <section class="col-md-12 dm-p-l-imp-0 no-pad-right section__wrapper checkbox__section">
            <fieldset>
                <div class="input__checkbox">
                    <input type="checkbox" [(ngModel)]="isFilterCheckEnable" (ngModelChange)="onFilterChange()"
                        class="input__checkbox--focus set-focus" value="None" id="viewGR" name="check"
                        aria-label="Only show Goods Receipts that require action" />
                    <label for="viewGR"></label>
                </div>
                <label for="viewGR" class="font-caption-alt input__checkbox--text">
                    Only show Goods Receipts that require action
                </label>
            </fieldset>
        </section>
    </section>
</section>
<section class="col-sm-12 col-xs-12 gr-filters" *ngIf="deviceFactory.isMobile()" filters-scroll
    fab-filter="#dminvoice_filter" fab-button="#fabButton">
    <section class="tile-header">
        <section class="col-sm-6 col-xs-8 dm-p-l-imp-0">
            <div class="current-view font-label col-sm-4 dm-p-l-imp-0">Currently viewing</div>
            <div class="ddl-text font-label dm-p-l-imp-0 col-sm-8">
                {{selectedPo? selectedPo : "All Purchase Orders,"}} {{isFilterCheckEnable ? "GR only" : ""}}
            </div>
        </section>
        <section class="col-sm-6 col-xs-4">
            <div>
                <button class="edit-filter" id="dminvoice_filter">
                    <span class="icon icon-filter"></span> Edit Filters
                </button>
            </div>
        </section>
    </section>
    <button id="fabButton" class="fabButton" aria-label="edit filter">
        <div class="innerdiv">
            <span class="icon icon-filter"></span>
        </div>
    </button>
</section>