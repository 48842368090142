<div class="dm-milestones-modal">
    <div class="roledetailsdialogcontent" *ngIf="!isManage">
        <div class="modal-header reject-popUp-noBorder">
            <button type="button" id="accessibilityConstants.CloseRejectButton" class="close set-focus" [attr.aria-label]="(isConfirm ? 'Confirm' : 'Unconfirm') +'Milestone Dialog Close'" (click)="closeModal()" (keydown)="moveFocusPrev($event, 'accessibilityConstants.ConfirmMilestoneLink')">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
            <h4 class="modal-title font-title" id="myModalLabel">
                {{isConfirm ? "Confirm" : "Unconfirm"}} Milestone </h4>
        </div>
        <div class="modal-body confirm-check">
            <p class="font-caption-alt">
                Are you sure you want to {{isConfirm ? "confirm" : "unconfirm"}} this milestone?
            </p>
            <dm-loading [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="isComponentLoading">
            </dm-loading>
        </div>
        <div class="modal-footer reject-popUp-noBorder">
            <button type="button" id="accessibilityConstants.Cancel" class="fxp-btn fxp-btn--secondary" (click)="closeModal()">
                Cancel
            </button>
            <button type="button" id="accessibilityConstants.ConfirmMilestoneLink" class="fxp-btn fxp-btn--primary" (click)="confirmContinue()" (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseRejectButton')">
                {{isConfirm ? "Confirm" : "Unconfirm"}}
            </button>
        </div>
    </div>
    <div class="roledetailsdialogcontent" *ngIf="isManage">
        <dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'150px'"
        [isLoading]="isComponentLoadingManage">
        <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="isComponentLoadingManage">
        </dm-loading>
            <div *ngIf="!isComponentLoadingManage">
                <!-- Form section -->
                <form [formGroup]="editManageMilestoneForm">
                        <div class="dm-form-entity milestone-form-entity">
                            <label class="dm-form-entity__label" for="milestoneName">
                                Name
                            </label>
                            <!-- <p>
                                <span [textContent]="milestoneName"></span>
                            </p> -->
                            <input class="font-caption-alt milestone-input milestone-input-disabled" type="text" maxlength="40" disabled = "true"
                                id="milestoneNameDesc" name="milestoneName" aria-label="Milestone name" formControlName="milestoneName">
                        </div>
                        <div class="dm-form-entity milestone-form-entity" >
                            <label class="dm-form-entity__label" for="billingValue">
                                Billing Value
                            </label>
                            <!-- <p>
                                <span [textContent]="milestoneBillingValue"></span>
                            </p> -->
                            <input class="font-caption-alt milestone-input milestone-input-disabled" type="text" maxlength="40" disabled = "true"
                                id="billingValue" name="milestoneBillingValue" aria-label="Billing Value" formControlName="milestoneBillingValue">
                        </div>
                        <div class="dm-form-entity milestone-form-entity" >
                            <label class="dm-form-entity__label" for="milestoneContractedDueDateId">
                                Contracted Due Date
                            </label>
                            <br/>
                            <dm-datepicker id="milestoneContractedDueDateId" name="milestoneContractedDueDate" formControlName="milestoneContractedDueDate"
                                [disabled]="true" [modelDate]="milestoneContractedDueDate"
                                [isDatePickerV2]="true">
                            </dm-datepicker>
                        </div>
                        <div class="dm-form-entity milestone-form-entity" *ngIf="true">
                            <label class="dm-form-entity__label" for="milestonePlannedCompletionDateId">
                                Planned Completion Date
                            </label>
                            <dm-datepicker id="milestonePlannedCompletionDateId" name="milestonePlannedCompletionDate" formControlName="milestonePlannedCompletionDate"
                                [disabled]="false" [modelDate]="milestonePlannedCompletionDate.value"
                                [isDatePickerV2]="true" (onDateChange)="onMilestonePlannedCompletionDateChange($event)"
                                [showErrorBorder]="!isMilestonePlannedCompletionDateValid">
                            </dm-datepicker>
                            <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                                *ngIf="!isMilestonePlannedCompletionDateValid">
                                {{editMilestoneDateErrorMessages.MilestonePlannedCompletionDateCantBeLessThanContractSignatureDate}}
                            </span>
                        </div>
                        <div class="dm-form-entity milestone-form-entity" >
                            <label class="dm-form-entity__label" for="milestoneActualCompletionDateId">
                                Actual Completion Date
                            </label>
                            <dm-datepicker id="milestoneActualCompletionDateId" name="milestoneActualCompletionDate" formControlName="milestoneActualCompletionDate"
                                [disabled]="false" [modelDate]="milestoneActualCompletionDate.value"
                                [isDatePickerV2]="true" (onDateChange)="onMilestoneActualCompletionDateChange($event)"
                                [showErrorBorder]="!isMilestoneActualCompletionDateValid">
                            </dm-datepicker>
                            <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" 
                                aria-live="polite"
                                *ngIf="!isMilestoneActualCompletionDateValid">
                                {{editMilestoneDateErrorMessages.MilestoneActualCompletionDateCantBeLessThanContractSignatureDate}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" 
                                aria-live="polite"
                                *ngIf="!isMilestoneActualCompletionPresentDate">
                                {{editMilestoneDateErrorMessages.MilestoneActualCompletionDateCantBeGreaterThanCurrentDate}}
                            </span>
                        </div>
                    <div class="clearfix"></div>
                </form>
            </div>
            <div additionalFooterButtons>
                <button type="button" id="accessibilityConstants.ConfirmMilestoneLink" class="fxp-btn fxp-btn--primary dm-btn" 
                *ngIf="!isComponentLoadingManage" (click)="confirmMilestoneDates()" [disabled]="saveMilestoneDateButtonDisabled()" >
                    Submit
                </button>
            </div>
        </dm-modal-v2>
    </div>
</div>



