<dm-modal-v2 class="poTransactionsModal" [modalHeaderContent]="modalContent" [modalMinHeight]="'430px'"
  [hasNonOverflowContent]="true" [showFooter]="false">
  <div class="poTransactionsModal__infoDetailsContainer">
    <span class="poTransactionsModal__infoLabel">Description</span>
    <span class="poTransactionsModal__infoDescription dm-p-r-20">{{poLineItemDetails?.description}}</span>
    <span class="poTransactionsModal__infoLabel">Supplier</span>
    <span class="poTransactionsModal__infoDescription dm-p-r-20">{{poDetails?.vendor}}</span>
    <span [ngStyle]="poLineItemDetails?.typeColorCode" class="dm-badge dm-contract-type dm-m-r-20">
      {{poLineItemDetails?.type}}
    </span>
    <span class="poTransactionsModal__infoLabel">Order Quantity</span>
    <span class="poTransactionsModal__infoDescription dm-p-r-20">{{poLineItemDetails?.quantity}}</span>
    <span class="poTransactionsModal__infoLabel">Line Item Amount</span>
    <span class="poTransactionsModal__infoDescription dm-p-r-20">{{poLineItemDetails?.lineItemAmount}}</span>
    <span class="poTransactionsModal__infoLabel">Total Consumed PO</span>
    <span class="poTransactionsModal__infoDescription dm-p-r-20">{{poLineItemDetails?.deliveredText}}</span>
    <span *ngIf="poLineItemDetails?.type === PurchaseOrderType.TM">
      <span class="poTransactionsModal__infoLabel">Cost Rate</span>
      <span class="poTransactionsModal__infoDescription dm-p-r-20">{{poLineItemDetails?.itemRate}}
        {{poLineItemDetails?.currency}}/{{poLineItemDetails?.uom}}</span>
    </span>

    <span class="poTransactionsModal__infoLabel">UoM <dm-tooltip role="tooltip" class="dm-tooltipinline"
        [dmPlacement]="'bottom-left'" [dmIcon]="'tile__icon--info icon icon-info'"
        [ngbTooltipContent]="'Unit of Measurement'" ariaLabelToolTip="Unit of Measurement">
      </dm-tooltip>
    </span>
    <span class="poTransactionsModal__infoDescription dm-p-r-20">{{poLineItemDetails?.uom}}</span>
  </div>
  <div class="poTransactionsModal__loaderContainer">
    <dm-loading loader [loadingText]="'transaction details'" [showLoading]="true" class="dm-p-10"
      *ngIf="isLoadingPOTransactionDetails">
    </dm-loading>

  </div>
  <div *ngIf="!isLoadingPOTransactionDetails">
    <dm-no-data *ngIf="poLineItemDetails.type === 'EXP'" class="dm-p-10"
      [noDataText]="'PO History is not applicable for expense PO items'" [showImage]="true">
    </dm-no-data>
    <div role="grid" class="dm-po-transactions-grid" *ngIf="poLineItemDetails.type !== 'EXP'">
      <div role="rowgroup" class="dm-grid-view-table dm-po-transactions-table">
        <div role="row" class="dm-grid-view-table__row"
          [ngClass]="{'dm-grid-view-table__row-TM': poLineItemDetails.type === 'T&M', 'dm-grid-view-table__row': poLineItemDetails.type !== 'T&M' }">
          <div class="dm-grid-view-table__headerCell"></div>
          <div role="columnheader" class="dm-grid-view-table__headerCell">Posted Date
          </div>
          <div role="columnheader" *ngIf="poLineItemDetails.type === 'T&M'"
            class="dm-grid-view-table__headerCell display-inline-imp">Resource Name
            <dm-tooltip [dmPlacement]="'bottom-left'"
              [ngbTooltipContent]="'Resource name is only applicable for T&M Purchase Orders'"
              [dmIcon]="'tile__icon--info icon icon-info'"
              ariaLabelToolTip="Resource name is only applicable for T&M Purchase Orders">
            </dm-tooltip>
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right display-inline-imp">
            Consumed<br /> Quantity
            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type" [dmPlacement]="'bottom'"
              [dmIcon]="'tile__icon--info icon icon-info'" [ngbTooltipContent]="'This consumed quantity is incremental'"
              ariaLabelToolTip="this consumed quantity is incremental">
            </dm-tooltip>
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right display-inline-imp">Consumed
            Cost<br />
            <span class="poLabel">({{poLineItemDetails?.currency}})/ ea</span>
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Invoiced Quantity<br />
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right display-inline-imp">Invoiced
            Amount
            <br /> <span class="poLabel">({{poLineItemDetails?.currency}})</span>
            <dm-tooltip [dmPlacement]="'bottom-right'"
              [ngbTooltipContent]="'Invoiced Quantity is applicable for only T&M projects'"
              [dmIcon]="'tile__icon--info icon icon-info'"
              ariaLabelToolTip="Invoiced Quantity is applicable for only T&M projects"></dm-tooltip>
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right display-inline-imp">Remaining
            Invoiced
            <br /> Quantity/Amount
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right filter--cell">Billablity
            <br />
            Status
            <div class="dropdown">
              <span type="text" id="filterBillableStatus" data-toggle="dropdown" aria-haspopup="true" class="dm-p-5  set-focus"
                aria-expanded="false" tabindex="0" role="button" aria-label="Space Button to see  Billablity Status Filter options" title="Billablity Status Filter">
                <i class="icon icon-filter"></i>
              </span>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="filterBillableStatus">
                <div class="display-grid">
                  <span class="dropdown-item" role="option" aria-selected="false" data-index="1" aria-posinset="1" aria-setsize="3" (click)="filterBillableStatus('all')" title="All" aria-label="All" tabindex="0" (keyup.enter)="filterBillableStatus('all')">All</span>
                  <span class="dropdown-item" role="option" aria-selected="false" data-index="2" aria-posinset="2" aria-setsize="3" (click)="filterBillableStatus('billable')" title="Billable" aria-label="Billable" tabindex="0" (keyup.enter)="filterBillableStatus('billable')">Billable</span>
                  <span class="dropdown-item" role="option" aria-selected="false" data-index="3" aria-posinset="3" aria-setsize="3" (click)="filterBillableStatus('nonBillable')" title="Non Billable" aria-label="Non Billable" tabindex="0" (keyup.enter)="filterBillableStatus('nonBillable')">Non Billable</span>
                </div>
              </div>
            </div>
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right filter--cell">Invoiced <br />
            Status
            <div class="dropdown">
              <span type="text" id="filterInvoicedStatus" data-toggle="dropdown" aria-haspopup="true" class="dm-p-5 set-focus"
                aria-expanded="false" tabindex="0" role="button" aria-label="Space Button to see Invoiced Status Filter options" title="Invoiced Status Filter">
                <i class="icon icon-filter"></i>
              </span>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="filterInvoicedStatus">
                <div class="display-grid">
                  <span class="dropdown-item" role="option" aria-selected="false" data-index="1" aria-posinset="1" aria-setsize="4" (click)="filterInvoicedStatus('all')" title="All" aria-label="All" tabindex="0" (keyup.enter)="filterInvoicedStatus('all')">All</span>
                  <span class="dropdown-item" role="option" aria-selected="false" data-index="2" aria-posinset="2" aria-setsize="4" (click)="filterInvoicedStatus('invoiced')" title="Invoiced" aria-label="Invoiced" tabindex="0" (keyup.enter)="filterInvoicedStatus('all')">Invoiced</span>
                  <span class="dropdown-item" role="option" aria-selected="false" data-index="3" aria-posinset="3" aria-setsize="4" (click)="filterInvoicedStatus('partiallyInvoiced')" title="Partially Invoiced" aria-label="Partially Invoiced" tabindex="0" (keyup.enter)="filterInvoicedStatus('all')">Partially
                    Invoiced</span>
                  <span class="dropdown-item" role="option" aria-selected="false" data-index="4" aria-posinset="4" aria-setsize="4" (click)="filterInvoicedStatus('notInvoiced')" title="Not Invoiced" aria-label="Not Invoiced" tabindex="0" (keyup.enter)="filterInvoicedStatus('notInvoiced')">Not Invoiced</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <dm-no-data *ngIf="!isLoadingPOTransactionDetails && poTransactionDetails?.length <=0" class="dm-p-10 set-focus"
          [noDataText]="noTransactionsText" [showImage]="true">
        </dm-no-data>
        <div class="dm-grid-view-table__body">
          <ng-container *ngFor="let item of poTransactionDetails">
            <div *ngIf="poTransactionDetails && poTransactionDetails?.length">
              <div role="row" class="dm-po-transactions-table__monthRow"
                [ngClass]="{'dm-grid-view-table__row-TM': poLineItemDetails.type === 'T&M', 'dm-grid-view-table__row': poLineItemDetails.type !== 'T&M' }">
                <div class="dm-grid-view-table__bodyCell">
                  <span tabindex="0" role="button" class="dm-p-x-10 set-focus" (click)="expandCollapse(item)"
                    (keyup.enter)="expandCollapse(item)" (keyup.Space)="expandCollapse(item)"
                    [title]="item.isExpanded ? 'Collapse expanded Month' : 'Expand collapsed Month'"
                    [attr.aria-expanded]="item.isExpanded ?true:false">
                    <span class="icon"
                      [ngClass]="{'icon-ChevronUp': item.isExpanded, 'icon-ChevronDown': !item.isExpanded}"
                      ></span>
                  </span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">{{item?.monthYear}}
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell" *ngIf="poLineItemDetails.type === 'T&M'">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <strong>{{item?.poTransactions | sum: 'goodsReceiptQuantity' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <strong>{{item?.poTransactions | sum: 'goodsReceiptAmount' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <strong>{{item?.poTransactions | sum: 'invoicedQuantity' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <strong>{{item?.poTransactions | sum: 'invoicedAmount' | dmDisplayCurrencyOrDash: currency}}</strong>
                  <span *ngIf="!item?.poTransactions"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div class="dm-grid-view-table__bodyCell text-align--right">
                </div>
                <div class="dm-grid-view-table__bodyCell text-align--right">
                </div>
                <div class="dm-grid-view-table__bodyCell text-align--right">
                </div>
                <div class="dm-grid-view-table__bodyCell text-align--right">
                </div>
              </div>
              <ng-container *ngIf="item?.isExpanded">
                <div role="row"
                  [ngClass]="{'dm-grid-view-table__row-TM': poLineItemDetails.type === 'T&M', 'dm-grid-view-table__row': poLineItemDetails.type !== 'T&M' }"
                  *ngFor="let poDetail of item?.poTransactions">
                  <div class="dm-grid-view-table__bodyCell">
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{poDetail?.goodsReceiptPostDate | date:'dd-MMM-yyyy'}}
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell" *ngIf="poLineItemDetails.type === 'T&M'">
                    {{poDetail?.resourceName}}
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{poDetail?.goodsReceiptQuantity | number : '1.2-2'}}
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{+poDetail?.goodsReceiptAmount | dmDisplayCurrencyOrDash: currency}}
                    <span *ngIf="!poDetail?.goodsReceiptAmount"
                      class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{poDetail?.invoicedQuantity | number : '1.2-2'}}
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{poDetail?.invoicedAmount | dmDisplayCurrencyOrDash: currency}}
                    <span *ngIf="!poDetail?.invoicedAmount"
                      class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <span>
                      {{poDetail?.remainingInvoicedQuantity | number : '1.2-2'}}
                    </span>/
                    <span>
                      {{poDetail?.remainingInvoicedAmount | dmDisplayCurrencyOrDash: currency}}
                      <span *ngIf="!poDetail?.remainingInvoicedAmount"
                        class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </span>
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right dm-m-r-20">
                    <span *ngIf="!poDetail?.isBillable" class="i-circle non-billable" [title]="'Non Billable'">NB</span>
                    <span *ngIf="poDetail?.isBillable" class="i-circle billable" [title]="'Billable'">B</span>
                  </div>
                  <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right dm-m-r-20">
                    <span *ngIf="poDetail?.invoiceStatus" class="i-circle billable" [title]="'Invoiced'">F</span>
                    <span *ngIf="!poDetail?.invoiceStatus" class="i-circle non-billable"
                      [title]="'Not Invoiced'">N</span>
                    <span *ngIf="poDetail?.partialInvoiceStatus" class="i-circle billable"
                      [title]="'Partially invoiced'">P</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</dm-modal-v2>