<section class="custom-datepicker" [ngClass]="{'disabled-datepicker': disabled}">
    <div class="btn-group drp-dwn ngbDropdown" autoClose="outside" ngbDropdown #datepickerPopup="ngbDropdown"
        (openChange)="toggledCalender($event)" placement="bottom-left top" display="dynamic" container="body">
        <section class="input-group datepicker-input-group" id="{{ 'simple-btn-keyboard-nav-' + ariaLabelButton }}"
            [ngClass]="{'error-border': showErrorBorder}" ngbDropdownToggle role="group">
            <input readonly id="{{'input' + ariaLabelButton}}" tabindex="-1"
                [attr.aria-label]="'input ' + ariaLabelButton + ' ' + placeholder" *ngIf="!isMandatory"
                class="font-caption-alt selected-item focus-in-high-contrast dm-p-l-10 datepicker-input"
                [ngClass]="{'datepicker-input-v2': isDatePickerV2}"
                [value]="modelDate ? formatDateToString(modelDate) : ''" [disabled]="disabled"
                [placeholder]="placeholder" (click)="$event.stopPropagation()">
            <input readonly id="{{'input' + ariaLabelButton}}" tabindex="-1"
                [ngClass]="{'datepicker-input-v2': isDatePickerV2}"
                [attr.aria-label]="'input ' + ariaLabelButton + ' ' + placeholder" *ngIf="isMandatory" required
                class="font-caption-alt selected-item focus-in-high-contrast dm-p-x-10 datepicker-input"
                [value]="modelDate ? formatDateToString(modelDate) : ''" [disabled]="disabled"
                [placeholder]="placeholder" (click)="$event.stopPropagation()">
            <span class="input-group-btn btn-holder">
                <button id="{{'calenderBtn_' + ariaLabelButton}}" class="btn btn-default focus-in-high-contrast datepicker-button"
                    type="button" [disabled]="disabled" [attr.aria-label]="modelDate ? 'date picker ' + ariaLabelButton + formatDateToString(modelDate) : 'date picker ' + ariaLabelButton"
                    (click)="moveFocusToCalender($event)" [ngClass]="{'datepicker-button-v2': isDatePickerV2}"
                    (keydown)="datepickerPopup.isOpen() ? moveFocusPrev($event, true) : moveFocusPrev($event, false)">
                    <i class="icon icon-calender" [ngClass]="{'icon-calender-v2': isDatePickerV2}"></i>
                </button>
            </span>
        </section>
        <section ngbDropdownMenu class="ngbDropdownMenu">
            <ngb-datepicker id="{{'datePicker_' + ariaLabelButton}}" [(ngModel)]="value" [minDate]="ngbMinDate"
                [startDate]="ngbStartDate" [maxDate]="ngbMaxDate" [disabled]="disabled" [attr.aria-label]="modelDate"
                (select)="onDateSelect(); datepickerPopup.close()" (navigate)="onNavigation($event)" navigation="select" class="clearfix datepicker-main">
            </ngb-datepicker>
            <br>
            <section class="datepicker-footer clearfix">
                <span class="btn-group pull-left">
                    <button type="button" class="btn btn-sm btn-info dm-m-r-8"
                        (click)="setDateToToday(); datepickerPopup.close()" (keydown)="setDateToToday($event)"
                        [disabled]="disabled">
                        Today
                    </button>
                    <button type="button" class="btn btn-sm btn-danger" (click)="clearDate(); datepickerPopup.close()"
                        (keydown)="clearDate($event)" [disabled]="disabled">
                        Clear
                    </button>
                </span>
                <span class="btn-group pull-right">
                    <button id="{{'closeBtn_' + ariaLabelButton}}" type="button" class="btn btn-sm btn-success"
                    (click)="datepickerPopup.close()" (keydown)="moveFocusNext($event); datepickerPopup.close()">
                        Close
                    </button>
                </span>
            </section>
        </section>
        <span class="sr-only" role="status" aria-atomic="true" id="month_value"></span>
        <span class="sr-only" id="caption_value"></span>
    </div>
</section>