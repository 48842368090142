import { Component, Inject, Injector, Input } from "@angular/core";
import { BroadcastEvent, Components, ForecastNotificationType, LogEventConstants, SourceConstants } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { IProcessNotification } from "../../../common/services/contracts/process-notifications.contracts";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { OpenSyncEACToPlanThresholdDialogComponent } from "../open-sync-eac-to-plan-threshold-dialog/open-sync-eac-to-plan-threshold-dialog.component";
import { FxpEventBroadCastService, UserInfoService } from "@fxp/fxpservices";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { IState } from "../../../store/reducers";
import { Store } from "@ngrx/store";
import { UpdateWriteOperationNotifications } from "../../../store/write-operation-notifications/write-operation-notifications.action";
import moment from "moment";
import { RemoveNotification } from "../../../store/notification-subscriptions/notification-subscriptions.action";
import { getWriteOperationNotificationByInstanceId } from "../../../store/write-operation-notifications/write-operation-notifications.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { InvalidateFinancialDetailsV2 } from "../../../store/financial-details-v2/financial-details-v2.action";
import { InvalidateEngagementDetails } from "../../../store/engagement-details/engagement-details.action";
import { InvalidateWbsStructures } from "../../../store/wbs-structures/wbs-structures.action";
import { LoadMyPortfolioEngagements } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.action";
import { InvalidateProjectDetails } from "../../../store/project-details/project-details.action";
import { SyncEACToPlanStatus } from "../../../common/services/contracts/sync-eac-to-plan-status.enum";
import { INotification, NotificationType } from "../../../common/services/contracts/notification-bar.contracts";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-sync-eac-to-plan-notification",
    templateUrl: "./sync-eac-to-plan-notification.component.html",
    styleUrls: ["./sync-eac-to-plan-notification.component.scss"]
})
export class SyncEACToPlanNotificationComponent extends DmComponentAbstract {
    @Input() public subscriptionId: string;

    public operation: IProcessNotification;
    public timeAgo: string;
    public showCloseOption: boolean;
    public syncEACToPlanStatus = SyncEACToPlanStatus;
    private loggedInUserData: any;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(UserInfoService) private userInfoService: UserInfoService,
        @Inject(FxpEventBroadCastService) private fxpBroadCastService: FxpEventBroadCastService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(FxpEventBroadCastService) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(Store) private store: Store<IState>,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector,
        @Inject(SharedFunctionsService) private sharedFunctionService: SharedFunctionsService,
    ) {
        super(dmLogger, Components.SyncEACToPlanNotificationComponent);
    }

    public ngOnInit(): void {
        this.loggedInUserData = this.userInfoService.getCurrentUserData();

        this.notificationService.subscribeToBulkInternalEngagementStatus({ GroupName: this.subscriptionId });

        // make get call for status
        this.configManagerService.initialize().then(() => {
            this.notificationService.getForecastNotificationSteps(ForecastNotificationType.SyncEACStatusChange, this.subscriptionId)
                .then((notification: IProcessNotification) => {
                    if (notification) {
                        this.logSyncEACToPlanOperation(notification);
                        this.showCloseOption = true;
                        this.store.dispatch(new UpdateWriteOperationNotifications(notification));
                        //  this.refreshWbsStatusChangeDone(notification, this.operation);
                        const oldOperationsObject = this.operation;
                        this.operation = notification;

                        if (this.operation && this.operation.LastUpdateTime) {
                            const stillUtc = moment.utc(this.operation.LastUpdateTime).toDate();
                            this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                        }

                        if (!oldOperationsObject || !oldOperationsObject.isItemExpanded || (!oldOperationsObject.Done && this.operation.Done)) {
                            this.operation.isItemExpanded = false;
                        } else {
                            this.operation.isItemExpanded = oldOperationsObject.isItemExpanded;
                        }
                    } else {
                        this.store.dispatch(new RemoveNotification(this.loggedInUserData.alias, this.subscriptionId));
                    }
                });
        });

        const notifications$ = this.store.select(getWriteOperationNotificationByInstanceId(this.subscriptionId));
        notifications$.pipe(untilDestroyed(this)).subscribe((wbsStatusChangeNotification: IProcessNotification) => {
            if (wbsStatusChangeNotification) {
                this.showCloseOption = true;

                if (wbsStatusChangeNotification.Is_GRM_Maintenance_Req_Checked && wbsStatusChangeNotification.Grm_Maintenance_Req_BatchID && wbsStatusChangeNotification.Grm_Maintenance_Req_BatchID !== ""){
                    const notificationEntry: INotification = {
                        userId: this.loggedInUserData.BusinessPartnerId,
                        id: wbsStatusChangeNotification.Grm_Maintenance_Req_BatchID,
                        type: NotificationType.CreateMaintenance,
                        entityId: this.getInProgressText(wbsStatusChangeNotification),
                        createdBy: this.loggedInUserData.BusinessPartnerId,
                        createdOn: new Date().toISOString(),
                        entityDescription: "Bulk Role Maintenance - " + this.getInProgressText(wbsStatusChangeNotification)
                    };
                    this.notificationService.createNotificationSubscriptionEntry(NotificationType.CreateMaintenance, this.loggedInUserData.BusinessPartnerId, this.getInProgressText(wbsStatusChangeNotification), wbsStatusChangeNotification.Grm_Maintenance_Req_BatchID).then(() => {
                        this.fxpBroadcastService.emit(BroadcastEvent.AutoGrmMaintenanceRequestSubmitted, { newNotification: notificationEntry });
                    });
                    // this.notificationService.addNotificationToStore(this.loggedInUserData.alias, this.loggedInUserData.BusinessPartnerId, this.getInProgressText(wbsStatusChangeNotification), wbsStatusChangeNotification.Grm_Maintenance_Req_BatchID, NotificationType.CreateMaintenance)
                    // this.notificationService.createNotificationSubscriptionEntry(NotificationType.CreateMaintenance, this.loggedInUserData.BusinessPartnerId, this.getInProgressText(wbsStatusChangeNotification), wbsStatusChangeNotification.Grm_Maintenance_Req_BatchID).then(() => {
                    //  this.notificationService.addNotificationToStore(this.loggedInUserData.alias, this.loggedInUserData.BusinessPartnerId, this.getInProgressText(wbsStatusChangeNotification), wbsStatusChangeNotification.Grm_Maintenance_Req_BatchID, NotificationType.CreateMaintenance);
                    // });
                    
                    
                    
                }
                //  this.refreshWbsStatusChangeDone(wbsStatusChangeNotification, this.operation);
                const oldOperationsObject = this.operation;
                this.operation = wbsStatusChangeNotification;

                if (this.operation && this.operation.LastUpdateTime) {
                    const stillUtc = moment.utc(this.operation.LastUpdateTime).toDate();
                    this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                }

                if (!oldOperationsObject || !oldOperationsObject.isItemExpanded || (!oldOperationsObject.Done && this.operation.Done)) {
                    this.operation.isItemExpanded = false;
                } else {
                    this.operation.isItemExpanded = oldOperationsObject.isItemExpanded;
                }
            }
        });
    }
    /**
     * Close and remove notification subscription for the current user.
     *
     * @memberof SyncEACToPlanNotificationComponent
     */
    public closeNotification(): void {
        this.showCloseOption = false;
        const loggedInUserAlias = this.userInfoService.getCurrentUserData().alias;
        this.notificationService.deleteNotificationSubscription(loggedInUserAlias, this.subscriptionId);
    }

    /**
     * Toggle whether notification is collapsed or expanded.
     *
     * @memberof SyncEACToPlanNotificationComponent
     */
    public toggleProcessNotificationItem(): void {
        this.operation.isItemExpanded = !this.operation.isItemExpanded;
    }

    /**
     * Opens the dialog for clicked notification step
     * @param step Step information on which dialog will open
     */
    public openThresholdDialog(): void {
        const modalRef: NgbModalRef = this.modalService.open(OpenSyncEACToPlanThresholdDialogComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 sync-eac-to-plan-threshold-modal in active",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.loadModalData(this.subscriptionId);
    }

    public getInProgressText(notification: IProcessNotification): string {
        if (notification) {
            if (notification.DemandId && notification.TaskId && notification.WbsId) {
                return notification.DemandId;
            }
            else if (notification.DemandId && notification.TaskId && !notification.WbsId) {
                return notification.TaskId;
            }
            else {
                return notification.WbsId;
            }
        }

        return "";
    }

    private refreshWbsStatusChangeDone(newOperation: IProcessNotification, oldOperation: IProcessNotification): void {
        if (newOperation && oldOperation && newOperation.InstanceId === oldOperation.InstanceId) {
            if (newOperation.Done && !oldOperation.Done) {
                let engagementId: string;
                let projectId: string;

                if (newOperation.WbsId && newOperation.WbsId.length > 12) {
                    engagementId = newOperation.WbsId.substring(0, 12);
                    if (newOperation.WbsId.length >= 19) {
                        projectId = this.getProjectId(newOperation.WbsId);
                    }
                } else {
                    engagementId = newOperation.WbsId;
                }

                this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                this.store.dispatch(new InvalidateWbsStructures(engagementId));
                this.store.dispatch(new LoadMyPortfolioEngagements(false));
                if (projectId !== "" || projectId !== undefined) {
                    this.store.dispatch(new InvalidateProjectDetails(projectId));

                    // TODO: This hack has been done because for some reason in manage ebs project,
                    // we store the data in the engagementdetails store slice. 
                    // This should be corrected once we move data to its correct place in the store
                    this.store.dispatch(new InvalidateEngagementDetails(projectId));
                }

                this.fxpBroadCastService.emit(BroadcastEvent.SyncEACToPlanCompleted, { orchestrationId: newOperation.InstanceId, wbsId: newOperation.WbsId, eventProcessed: false });
            }
            if (newOperation.Error && !oldOperation.Error) {
                // this.fxpBroadCastService.emit(BroadcastEvent.ShowReleaseAndActivate, { engagementId: newOperation.WbsId });
            }
            if (!newOperation.Done && newOperation.Attempt === 3 && oldOperation.Attempt === 2) {
                /// if its a third attempt for retry send an email notification     
                // this.fxpBroadCastService.emit(BroadcastEvent.SendFailureNotification, { engagementId: newOperation.WbsId });
            }
        }
    }

    private getProjectId(wbsId: string): string {
        // Return projectId from service/taskId
        return wbsId.substring(0, 16).concat("000");
    }

    private logSyncEACToPlanOperation(notification: IProcessNotification): void {
        const properties = {
            ...notification,
            Status: notification.Error ? "Failure" : "Success",
            WbsId: notification.WbsId,
            EntityType: this.sharedFunctionService.getEntityType(notification.WbsId),
            PjmXCV: this.dmLogger.generateGuid(),
        };
    
        this.dmLogger.logEvent(
            SourceConstants.Component.SyncEACToPlanNotificationComponent,
            SourceConstants.Method.LogSyncEACToPlanOperation,
            LogEventConstants.BPE.SyncEACToPlanNotification,
            properties
        );
    }
}