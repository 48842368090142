<ng-template #personCardPopup>
  <div class="person-card dm-p-20">
    <div class="display-flex">
      <div class="dm-m-r-15">
        <mgt-person [personDetails]="personDetails" [personImage]="personImage" avatar-size="large" view="avatar">
        </mgt-person>
      </div>
      <div *ngIf="personDetails?.alias">
        <div class="dm-p-b-5 person-card--name dm-tile--pjm_ellipses"
          [title]="personDetails.displayName">{{personDetails?.displayName | dmDisplayDashIfEmptyOrNull}}</div>
        <div class="dm-p-b-5 font-caption-alt">{{personDetails?.jobTitle | dmDisplayDashIfEmptyOrNull}}</div>
      </div>
    </div>
    <div class="icon-container dm-p-t-5">
      <a class="icon-full icon-chat dm-m-r-15 set-focus" *ngIf="canChat" [id]="'chat-' + personCardId"
        [href]="'https://teams.microsoft.com/l/chat/0/0?users='+personDetails?.alias+'@microsoft.com'" target="_blank" [attr.aria-label]="personDetails?.displayName + 'contact flyout' + 'open link in new tab'"
        [title]="'Send an IM to: ' + personDetails?.alias + '@microsoft.com'" (keydown.shift.tab)="personCardDropdown.close(); moveFocus('person-card-dropdown-link')"></a>
      <a class="icon-full icon-email dm-m-r-15 set-focus" [href]="'mailto:' + personDetails?.alias + '@microsoft.com'" [id]="'mail-' + personCardId" [attr.aria-label]="canChat ? '' : personDetails?.displayName + 'contact flyout'"
        [title]="'Send email message to: ' + personDetails?.alias + '@microsoft.com'"></a>
    </div>
  </div>
  <div class="person-card--additional-details-section dm-m-t-10">
    <div class="person-card--font dm-p-b-10">Contact</div>
    <div>
      <div class="dm-flex-align-center">
        <span class="icon-full icon-email dm-m-r-12 person-card--additional-details-section-icons person-card--font"></span>
        <a class="font-caption-alt set-focus" [id]="'contact-mail-' + personCardId" (keydown.Tab)="personCardDropdown.close(); moveFocus('person-card-dropdown-link')" 
          [href]="'mailto:' + personDetails?.alias + '@microsoft.com'" [title]="'Send email message to: ' + personDetails?.alias + '@microsoft.com'">{{ personDetails?.mail }}</a>
      </div>
    </div>
  </div>
</ng-template>

<div ngbDropdown #personCardDropdown="ngbDropdown" (mouseover)="personCardDropdown.open()" (mouseleave)="closePersonCardDropdown($event, personCardDropdown)"
  (keydown.shift.tab)="personCardDropdown.close()" container="body" display="dynamic" [id]="'person-card-dropdown-' + personCardId">
  <!--="ngbDropdown"-->
  <div *ngIf="avatarOnly">
    <div ngbDropdownToggle role="group">
      <mgt-person  [personDetails]="personDetails" [personImage]="personImage" avatar-size="large" view="avatar">
      </mgt-person>
    </div>
  </div>
  <div *ngIf="nameOnly" ngbDropdownToggle role="link">
    <a [id]="'person-card-dropdown-link' + personCardId" class="font-caption-alt dm-link" [attr.aria-label]="personDetails?.displayName + 'press down arrow key to open flyout. open link in new tab'" [href]="'#/profile/' + personDetails.alias +'/basic'" target="_blank"
    (keydown.ArrowDown)="canChat ? moveFocus('chat-') : moveFocus('mail-')" (keydown.Tab)="canChat ? moveFocus('chat-') : moveFocus('mail-')">
      {{ personDetails?.displayName }}
    </a>
  </div>
  <section [id]="'person-card-dropdown-menu-' + personCardId" class="col-sm-12 dropdown-menu"
    [ngClass]="{'dropdown-menu--large': personDetails?.jobTitle ===  roleFullName.AdditionalPrimaryProjectManager}"
    aria-label="notifications" ngbDropdownMenu role="menu"
    (mouseleave)="closePersonCardDropdown($event, personCardDropdown)">
    <ng-container [ngTemplateOutlet]="personCardPopup"></ng-container>
  </section>
</div>