import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { Components, FinancialType, ComponentFailureMessages, NoDataText } from "../../common/application.constants";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FinancialService } from "../../common/services/financial.service";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.selector";
import { getEntireInvoices } from "../../store/invoices/invoices.selector";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { IEntityFinancials } from "../financial-mgmt/financial.model";
import { IFinancialDetailsV2State } from "../../store/financial-details-v2/financial-details-v2.reducer";
import { IInvoiceItemModel, IProjectDataToFilter } from "./invoice-table-data/invoice-table-data.contract";
import { IInvoicesState } from "../../store/invoices/invoices.reducer";
import { IProjectDetailsV2, IEngagementDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IState } from "../../store/reducers";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { SortOptions } from "./invoice-table-data/invoice-table-data.component";
import { untilDestroyed } from "ngx-take-until-destroy";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../common/error.constants";
import { ProjectService } from "../../common/services/project.service";
import { IEcifIoConsumptionAPI } from "../../common/services/contracts/ecif-io-consumed-modal.contracts";


/**
 * InvoicesComponent
 *
 * @export
 * @class InvoicesComponent
 */
@Component({
    selector: "dm-invoices",
    templateUrl: "./invoices.html",
    styleUrls: ["./invoices.scss"],
})
export class InvoicesComponent extends DmComponentAbstract {
    public currencySymbol: string;
    public invoiceFilteredList: IInvoiceItemModel[];
    public invoiceNumber: string;
    public invoicedAmtPercentage: number = 0;
    public isInvoicesProgressBarLoading: boolean = true;
    public limitInvoices: number = -1;
    public noInvoicesText = NoDataText.NoInvoices;
    public projects: IProjectDataToFilter[];
    public selectedProjectId: string;
    public selectedStatus: string = "";
    public totalContractAmount: number;
    public totalInvoicedAmount: number = 0;
    public invoiceSortVal: SortOptions = SortOptions.Status;
    public isCurrentUserPartOfTeamStructure: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.CustomerInvoices;
    private engagementId: string;
    private invoiceList: IInvoiceItemModel[];

    /**
     * Creates an instance of InvoicesComponent.
     * @param {DeviceFactoryProvider} deviceFactory
     * @memberof InvoicesComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider))
        public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService)
        private sharedFunctionsService: SharedFunctionsService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(Store) private store: Store<IState>,
        @Inject(DMAuthorizationService)
        private dmAuthorizationService: DMAuthorizationService,
        @Inject(ProjectService) private projectService: ProjectService
    ) {
        super(dmLogger, Components.CustomerInvoices, [
            { component: Components.CustomerInvoicesTableData, isCritical: true },
            { component: Components.CustomerInvoicesFilter, isCritical: true },
        ]);
    }

    public async ngOnInit(): Promise<any> {
        this.sharedFunctionsService.focus("dm-invoice-projects-ddl", true);
        this.errorText = ComponentFailureMessages.EngagementInvoiceDataComponent;
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(
            this.stateService
        );
        this.selectedProjectId = this.sharedFunctionsService.getSelectedProjectId(
            this.stateService
        );
        this.tileContent = {
            title: "Customer Invoices",
            link: {
                name: "Learn more on Customer Invoices",
                url: "https://aka.ms/pjm-job-aid/customer-invoices",
                tooltipText: "Learn more about Customer Invoices",
                icon: "icon-education",
            },
        };
        const projectContext = this.selectedProjectId ? true : false;
        if (!this.selectedProjectId) {
            this.selectedProjectId = "";
            /* Set this as an empty string because the filter recognizes an empty string to set projects to "All".
                  Filter does not recognize undefined. */
        }

        const engagementDetails$ = this.store.select(
            getEntireEngagementDetails(this.engagementId)
        );
        const invoices$ = this.store.select(getEntireInvoices(this.engagementId));
        const financialDetails$ = projectContext
            ? this.store.select(getEntireFinancialDetailsV2(this.selectedProjectId))
            : this.store.select(getEntireFinancialDetailsV2(this.engagementId));

        observableCombineLatest(
            engagementDetails$,
            invoices$,
            financialDetails$,
            (
                engagementDetails: IEngagementDetailsState,
                invoices: IInvoicesState,
                financialDetails: IFinancialDetailsV2State
            ) => ({
                engagementDetails,
                invoices,
                financialDetails,
            })
        )
            .pipe(untilDestroyed(this))
            .subscribe(async ({ engagementDetails, invoices, financialDetails }) => {
                if (invoices.loaded) {
                    const invoiceList: IInvoiceItemModel[] = invoices.invoices;
                    this.invoiceFilteredList = invoices.invoices;
                    this.invoiceList = this.selectedProjectId
                        ? invoiceList.filter(
                            (invoice: IInvoiceItemModel) =>
                                invoice.project.filter(
                                    (project: IProjectDataToFilter) =>
                                        project.projectId === this.selectedProjectId
                                ).length
                        )
                        : invoiceList;
                }

                if (
                    financialDetails.loaded &&
                    invoices.loaded &&
                    engagementDetails.loaded
                ) {
                    const engagementDetailsResult = engagementDetails.engagementDetails;
                    if (this.selectedProjectId) {
                        if (engagementDetailsResult && engagementDetailsResult.projects) {
                            const filteredProjectDetailsResult =
                                engagementDetailsResult.projects.filter(
                                    (project: IProjectDetailsV2) =>
                                        project.id === this.selectedProjectId
                                );
                            this.projects = filteredProjectDetailsResult.map(
                                (x: IProjectDetailsV2): IProjectDataToFilter => ({
                                    projectId: x.id,
                                    projectName: x.name,
                                })
                            );
                        }
                    } else {
                        this.projects = this.getProjectsFromInvoices(
                            this.invoiceFilteredList
                        );
                    }
                    this.isCurrentUserPartOfTeamStructure =
                        this.dmAuthorizationService.isUserPartOfTeamStructure(
                            engagementDetailsResult
                        );
                    // Set the invoiced amount progress after getting data from invoices and financial details
                    const financials: IEntityFinancials =
                        financialDetails.financialDetails;
                    const totalBIFAmount = await this.getBifAmountBasedOnEngagementId(
                        this.engagementId
                    );
                    this.setInvoicedAmtProgress(
                        this.invoiceList,
                        financials,
                        totalBIFAmount
                    );
                }

                this.refreshOnItemInvalidation(
                    engagementDetails,
                    financialDetails,
                    invoices
                );
                this.setErrorsBasedOnItemState(
                    engagementDetails,
                    financialDetails,
                    invoices
                );
                this.setLoadersBasedOnItemStateCosmetic(
                    engagementDetails,
                    financialDetails,
                    invoices
                );
                if (
                    engagementDetails.error ||
                    invoices.error ||
                    financialDetails.error
                ) {
                    this.isServerError = true;
                }
            });
    }

    /**
     * Update the selected status when the status has been changed.
     *
     * @param {string} selectedStatus
     * @memberof InvoicesComponent
     */
    public onStatusChange(selectedStatus: string): void {
        if (this.selectedStatus !== selectedStatus) {
            this.selectedStatus = selectedStatus;
            this.invoiceFilteredList = this.selectedStatus
                ? this.invoiceList.filter(
                    (invoice: IInvoiceItemModel) =>
                        invoice.status === this.selectedStatus
                )
                : this.invoiceList;
            const length = this.invoiceFilteredList.length
                ? this.invoiceFilteredList.length
                : "No";
            document.getElementById("filterResultMsg").innerHTML =
                this.selectedStatus + length + "result found";
        }
    }

    /**
     * Update the selected invoice number when the invoice number has been changed.
     *
     * @param {string} invoiceNumber
     * @memberof InvoicesComponent
     */
    public onInvoiceNumberChange(invoiceNumber: string): void {
        this.invoiceNumber = invoiceNumber;
        const length = this.invoiceFilteredList.length
            ? this.invoiceFilteredList.length
            : "No";
        document.getElementById("filterResultMsg").innerHTML =
            this.invoiceNumber + length + "result found";
    }

    /**
     * Update the selected Project ID when the project has been changed.
     *
     * @param {string} selectedProjectId
     * @memberof InvoicesComponent
     */
    public onProjectChange(selectedProjectId: string): void {
        this.selectedProjectId = selectedProjectId;
        const length = this.invoiceFilteredList.length
            ? this.invoiceFilteredList.length
            : "No";
        document.getElementById("filterResultMsg").innerHTML =
            this.selectedProjectId + length + "result found";
    }

    /**
     * Takes in a list of invoices and pulls out the unique projects within it. Returns the list of projects without any duplicates.
     */
    private getProjectsFromInvoices(
        invoices: IInvoiceItemModel[]
    ): IProjectDataToFilter[] {
        const projects: IProjectDataToFilter[] = [];
        if (invoices && invoices.length) {
            invoices.forEach((invoice: IInvoiceItemModel) => {
                invoice.project.forEach((project: IProjectDataToFilter) => {
                    if (
                        !projects.filter((x) => x.projectId === project.projectId).length
                    ) {
                        projects.push(project);
                    }
                });
            });
        }
        return projects;
    }

    /**
     *
     * Gets the total contract amount
     * @private
     * @param {IEngagementFinancial} financialDetails
     * @returns {number}
     * @memberof InvoicesComponent
     */
    private getTotalContractAmount(
        financialDetails: IEntityFinancials,
        totalBIFAmount: number
    ): number {
        let totalRevenueCFP: number;
        /** Get total revenue from contract baseline financial type always */
        const contractBaseLineFinancials =
            this.financialService.getFinancialDetailsFromParentForV2Object(
                financialDetails,
                FinancialType.ContractBaseline
            );
        if (contractBaseLineFinancials && contractBaseLineFinancials.revenue) {
            totalRevenueCFP = contractBaseLineFinancials.revenue;
        }

        // TOD0 - will be removed once SAP resolves bug as revenue amount cannot be zero.
        // Total Contract amount = Total Revenue from Current Financial Plan(CFP) - BIF Amount
        const totalContractAmount = totalRevenueCFP
            ? totalRevenueCFP - totalBIFAmount
            : 0;
        return totalContractAmount;
    }

    /**
     *
     * Gets the total invoiced amount
     * @private
     * @param {IInvoiceItemModel[]} invoiceList
     * @returns {number}
     * @memberof InvoicesComponent
     */
    private getTotalInvoicedAmount(invoiceList: IInvoiceItemModel[]): number {
        let totalInvoicedAmount: number = 0;
        if (invoiceList && invoiceList.length) {
            this.currencySymbol = invoiceList[0].currencySymbol;

            // Calculates the total invoiced amount
            invoiceList.forEach((invoice: IInvoiceItemModel) => {
                totalInvoicedAmount += invoice.invoiceTotal;
            });
        }
        return totalInvoicedAmount;
    }

    /**
     *
     * Calculate Total invoiced amount and total contract amount
     * Show the progress of total invoiced amount
     * @private
     * @memberof InvoicesComponent
     */
    private setInvoicedAmtProgress(
        invoiceList: IInvoiceItemModel[],
        financialDetails: IEntityFinancials,
        totalBIFAmount: number
    ): void {
        this.totalContractAmount = this.getTotalContractAmount(
            financialDetails,
            totalBIFAmount
        );
        this.totalInvoicedAmount = this.getTotalInvoicedAmount(invoiceList);

        if (this.totalContractAmount && this.totalContractAmount !== 0) {
            this.invoicedAmtPercentage =
                (this.totalInvoicedAmount / this.totalContractAmount) * 100;
        }

        this.isInvoicesProgressBarLoading = false;
    }

    private getBifAmountBasedOnEngagementId(wbsId: string): Promise<number> {
        let totalBIFAmount: number = 0;
        return this.projectService
            .getEcifIoConsumption(wbsId)
            .then((response: IEcifIoConsumptionAPI[]) => {
                for (const consumptionAmount of response) {
                    const ioDetails = consumptionAmount.ioDetails;
                    for (const ioDetail of ioDetails) {
                        totalBIFAmount = totalBIFAmount + ioDetail.totalFunds;
                    }
                }
                return totalBIFAmount;
            });
    }
}
